import React from "react";
import SectionTitleFour from "../ui/section-titles/SectionTitleFour";

const AboutContentTwo = () => {
  return (
    <div className="dg__service__area bg--white padding--hor">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 col-sm-12 col-12">
            <div className="service__chart">
              <div className="thumb">
                <img
                  src={process.env.PUBLIC_URL + "/images/about/4.png"}
                  alt="chart"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-xl-6 offset-xl-1 col-md-12 col-sm-12 col-12 sm__mt--40 md__mt--40">
            <div className="service__inner">
              {/* section title */}
              <SectionTitleFour title="The best in decentralized technologies complete solutions." />
              <p>
                Decentralized technologies complete solutions.
                Decentralized technologies will be the main factor to develop our future, Decentralisation is the best solution to handle emergency situations.

              </p>
              <p>
                That’s why we Develop the cutting-edge decentralized technology in complete solutions. Enable and back emerging projects that are building the future.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContentTwo;
