
import React, { useState } from "react";
import ReactPlayer from "react-player";
import myVideo from '../../assets/media/cryptoVideo.mp4'
const videoPlayer = () => {
    return (
        <div className="row">
            <ReactPlayer
                light= '/images/missionVideoCover.jpg'
                className=''
                url={myVideo}                
                controls={true}
                playing={true}
            />

        </div >
    );
};

export default videoPlayer;
