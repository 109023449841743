import PropTypes from "prop-types";
import React from "react";
// import { Link } from "react-router-dom";
import ParticleTwo from "../particles/ParticleTwo";

const Breadcrumb = ({ title }) => {
  return (
    <div
      className="ht__bradcaump__area"
      style={{
        background: `rgba(0, 0, 0, 0) url(${process.env.PUBLIC_URL +
          "/images/bg/7.jpg"}) repeat scroll center center/105% 100%`
      }}
    >
      <div className="ht__bradcaump__container">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
            </div>
          </div>
        </div>
      </div>
      {/* particle */}
      <ParticleTwo />
    </div>
  );
};

Breadcrumb.propTypes = {
  title: PropTypes.string
};

export default Breadcrumb;
