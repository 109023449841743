import React from "react";

const VideoPopup = () => {
  return (
    <div
      className="dg__video__area"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/images/bg/8.jpg"})`
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="dg__video__inner">
              <h2>Want to know more about us?</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPopup;
