import PropTypes from "prop-types";
import React from "react";

const TeamSingle = ({ data }) => {
  return (
    <div className="col-lg-3 col-md-6 col-sm-6 col-12">
      <div className="team">
        <div className="thumb">
          <a
            href={process.env.PUBLIC_URL + data.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={process.env.PUBLIC_URL + data.image} alt="team images" />
          </a>
        </div>
        <div className="team__content">
          <h4>
            <a
              href={process.env.PUBLIC_URL + data.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.name}
            </a>
          </h4>
          <p className="p-2">{data.position}</p></div>
      </div>
    </div>
  );
};

TeamSingle.propTypes = {
  data: PropTypes.object
};

export default TeamSingle;
