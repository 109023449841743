import React from "react";
import { Link } from "react-router-dom";
import VideoPlayer from './../../components/video-popups/videoPlayer'
const HowWorks = () => {
  return (
    <div className="dg__work__area how__work">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 home-about-container ">
            <div className="thumb ml--4 pl--20">
              {/* <img
                src={process.env.PUBLIC_URL + "/images/about/4.png"}
                alt="computer images"
                className="home-about-img"
              /> */}
               <VideoPlayer/> 
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 sm__mt--40 md__mt--20 pt--20">
            <div className="content pt-10">
              <h2>Mission</h2>
              <h6>
                A decentralized technology enabling a better tomorrow globally.
              </h6>
              <div className="col-10 p-0 ">
                <p> 
                  Develop the cutting-edge decentralized technology in decentralized technologies complete solutions. Enable and back emerging projects that are building the future.
                </p>
              </div>

              <Link
                className="slide__btn dg__btn"
                to={process.env.PUBLIC_URL + "/contact"}
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWorks;
