import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import LayoutTwo from "../layouts/LayoutTwo";
import Breadcrumb from "../components/breadcrumbs/Breadcrumb";
import ContactMap from "../components/contact-maps/ContactMap";
import ReactGA from 'react-ga';

const Contact = () => {
  useEffect(()=>{
    ReactGA.pageview('Contact')
  },[])
  return (
    <Fragment>
      <MetaTags>
        <title>MetaBlock Technologies | Contact</title>
        <meta
          name="description"
          content="Contact page of React JS Crypto Currency Template."
        />
      </MetaTags>
      <LayoutTwo theme="white">
        {/* breadcrumb */}
        <Breadcrumb title="CONTACT" />
        {/* contact page content */}
        <section className="pt--50">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="contact__title">
               
                  <h2>Get your vision done, Contact us today!</h2>
                  <p>
                  Find the nearest office to your location
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row align-items-center py-5">
              <div className="col-lg-6 col-md-12 col-12">
                  {/* contact map */}
                  <img
                    src={process.env.PUBLIC_URL + "/images/about/4.png"}
                    alt="conatct images"
                    className="img img-fluid"
                  />
              </div>
              <div className="col-lg-6 col-md-12 col-12 sm__mt--40 md__mt--40">
                <div className="dg__contact__wrapper">
                  <form className="contact-form">
                    <div className="single-contact-form">
                      <span>Full Name</span>
                      <input type="text" name="firstname" />
                    </div>
                    <div className="single-contact-form">
                      <span>Email</span>
                      <input type="email" name="email" />
                    </div>
                    <div className="single-contact-form">
                      <span>Subject</span>
                      <input type="text" name="subject" />
                    </div>
                    <div className="single-contact-form message">
                      <span>Message</span>
                      <textarea name="message" defaultValue={""} />
                    </div>
                    <div className="contact-btn">
                      <button type="submit">
                        Submit
                        <span className="ti-arrow-right" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LayoutTwo>
    </Fragment>
  );
};

export default Contact;
