import React from "react";

const CurrencyCalculationTwo = () => {
  return (
    <div className="dg__converter__area converter__wrapper bg--white section-padding--xl">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="thumb">
              <img
                src={process.env.PUBLIC_URL + "/images/about/5.png"}
                alt="converter images"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="content">
              <h2>Today’s Converter</h2>
              <p className="first">
              Know how much your token worth on a daily basis with our easy-to-use converter.

              </p>
              <div className="bit__convert">
                <div className="single__convert">
                  <select>
                    <option>BTC</option>
                    <option>USD</option>
                    <option>EUR</option>
                    <option>LTC</option>
                    <option>RUB</option>
                  </select>
                  <input type="text" placeholder="01" />
                  <span>Bitcoin Number</span>
                </div>
                <div className="single__convert">
                  <select>
                    <option>USD</option>
                    <option>BTC</option>
                    <option>EUR</option>
                  </select>
                  <input type="text" placeholder="11750.55" />
                  <span>USD Number</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrencyCalculationTwo;
