import React from "react";
import { Link } from "react-router-dom";

const Navigation = () => {
  return (
    <nav className="mainmenu__nav">
      <ul className="mainmenu">
        <li>
          <Link to={process.env.PUBLIC_URL + "/"}>
            Home
          </Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/service"}>
            Service
          </Link>
        </li>
        <li>
          <Link to={process.env.PUBLIC_URL + "/about"}>
            Methodology
          </Link>
        </li>
        {/* <li>
          <Link to={process.env.PUBLIC_URL + "/team"}>Our Team</Link>
        </li> */}
        {/* <li className="drop">
          <Link to={process.env.PUBLIC_URL + "#/"}>
            Pages <IoIosArrowDown />
          </Link>
          <ul className="dropdown">
            <li>
              <Link to={process.env.PUBLIC_URL + "/about"}>Methodology</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/team"}>Team Page</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/wallet"}>Wallet Page</Link>
            </li>
          </ul>
        </li> */}
        <li>
          <Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
