import React from "react";
import SectionTitleFour from "../ui/section-titles/SectionTitleFour";

const AboutContent = () => {
  return (
    <div className="about__dgtaka about--2 section-padding--xl">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-12 col-sm-12 col-md-12">
            <div className="dg__secure__inner">
              {/* section title */}
              <SectionTitleFour title="Methodology" />

              <p>
              At MetaBlock Technologies, we have the business expertise and depth of technical skills necessary for projects of varying scope and complexity. Our staff of experienced architects, technical account managers, and developers has proven continuously proven the ability to quickly develop quality products on time and on budget.
              </p>
              <p>
              We work with our clients to take projects from the idea or concept phase straight through to completed products. Our methodical approach gives our clients complete project control, with the opportunity for input at every step in the process, ensuring that the project will be completed with all expectations met or exceeded. Our ability to work transparently ensures that we are an integrated, not separated, part of our client’s development process. </p>
            </div>
          </div>
          <div className="col-lg-6 col-12 col-sm-12 col-md-12 sm__mt--40 md__mt--40">
            <div className="dg__secure__thumb">
              <img
                src={process.env.PUBLIC_URL + "/images/about/6.png"}
                alt="secure images"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
