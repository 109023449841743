import React from "react";
import teamData from "../../data/teams/team-one.json";
import TeamSingle from "../../components/teams/TeamSingle.js";

const TeamContent = () => {
  return (
    <section className="dg__team__area section-padding--xs bg--white">
      <div className="container">
        <div className="team__wrapper">
          <div className="row">
            {teamData &&
              teamData.map((single, key) => {
                return <TeamSingle data={single} key={key} />;
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamContent;
