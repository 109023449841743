import React from 'react'
//another about us style 
// import SectionTitleTwo from '../../components/ui/section-titles/SectionTitleTwo'
// import workprocessData from "../../data/work-processes/work-process-one.json"
// import WorkProcessSingle from '../../components/work-processes/WorkProcessSingle'
import SectionTitleSix from "../../components/ui/section-titles/SectionTitleSix";
import serviceData from "../../data/service-lists/service-list-one.json";
import ServiceListSingle from "../../components/service-contents/ServiceListSingle";
const WorkProcess = () => {
  return (
    <div className="dg__working__process pb--120 pt--50 bg--white">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* section title */}
            <SectionTitleSix
              title="Who We Are"
              text="A decentralized technology enabling a better tomorrow globally."
            />
          </div>
        </div>
      </div>
      <div className="space__dec">
        <div className="custom__service__width">
          {serviceData &&
            serviceData.map((single, key) => {
              return <ServiceListSingle data={single} key={key} />;
            })}
        </div>
      </div>
    </div>
  )
}

export default WorkProcess
